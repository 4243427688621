"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";

function NotFoundBtn() {
  const router = useRouter();

  return (
    <>
      <button
        onClick={() => router.back()}
        className="jlt-text-sm jlt-text-primary-foreground jlt-font-medium jlt-bg-primary jlt-rounded-md jlt-py-2 jlt-px-4"
      >
        {/* <span className="jlt-rotate-180">{getIcon("arrowRight")}</span> */}
        <span>Go Back</span>
      </button>
      <Link
        href="/"
        className="jlt-inline-flex jlt-items-center jlt-justify-center jlt-whitespace-nowrap jlt-rounded-md jlt-text-sm jlt-font-medium jlt-ring-offset-background jlt-transition-colors focus-visible:jlt-outline-none focus-visible:jlt-ring-2 focus-visible:jlt-ring-ring focus-visible:jlt-ring-offset-2 disabled:jlt-pointer-events-none disabled:jlt-opacity-50 active:jlt-scale-95 jlt-transition-all jlt-bg-primary jlt-text-primary-foreground hover:jlt-bg-primary/90 jlt-primary jlt-h-9 jlt-px-4 jlt-py-2 jlt-bg-purple-500 hover:jlt-bg-purple-500/80 jlt-text-white"
      >
        Take Me Home
      </Link>
    </>
  );
}

export default NotFoundBtn;
